import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BildHeader from "../../images/services/services/header.jpg"
import BildOben from "../../images/services/services/oben.jpg"
import SubNavigation from "../../components/SubNavServices"
import Termin from "../../components/Termin"

const ServicesSubpage: React.FC = () => {
  const siteTitle = "Services – Dienstleistungen"
  const [isSubMenuOpen, setSubMenuOpen] = useState(false)

  return (
    <Layout title={siteTitle}>
      <SEO title={siteTitle} />
      <section className="wrapper">
        <div className="headerImage">
          <img src={BildHeader} />
        </div>
      </section>
      <Termin />
      <SubNavigation
        isSubMenuOpen={isSubMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        activeMenuText="Services"
      />
      <section className="wrapper greyColor content">
        <h1>Services</h1>
        <h2 className="contentSubtitle">SCHWARZ Optik – Seit 1992 in Heiden</h2>
        <div className="contentColumns">
          <div className="contentColumn">
            <div className="imageCollection">
              <div className="imageRow">
                <img src={BildOben} />
              </div>
            </div>
            <p className="imageCaption">
              SCHWARZ Optik Heiden bietet Ihnen eine Verträglichkeitsgarantie
              auf alle im eigenen Atelier gefertigten Brillen und stellt dazu
              einen persönlichen Brillenpass aus.
            </p>
          </div>
          <div className="contentColumn">
            <h2>Kompetenz für gesundes Sehen</h2>
            <p>
              Eine zeitgemässe Infrastruktur und ein qualifiziertes Team bilden
              die Basis für unseren Erfolg. Permanente Weiterbildung für die
              Optimierung des Kundenservices ist selbstverständlich.
            </p>
            <p>
              Bei SCHWARZ Optik in Heiden finden Sie die Ansprechpartner und die
              Produkte, die Ihren Voraussetzungen und Bedürfnissen optimal
              entsprechen. Herzlich willkommen!
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ServicesSubpage
